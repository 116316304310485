.App {
  text-align: center;
  margin-top: 20px;
}
.App-bold {
  text-align: center;
  font-size: 30px;
}
.App-normal {
  text-align: center;
}
.App-right {
  text-align: center;
  /* margin-right: 20px; */
}
.App-left {
  text-align: center;
  /* margin-left: 20px; */
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.checked {
  color: orange;
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.loader {
  margin:auto;
  width: 50px;
  margin-top: 300px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #3498db; /* Blue */
  border-radius: 50%;
  width:50px;
  height: 50px;
  animation: spin 2s linear infinite;
  }

@keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
@-webkit-keyframes spin {
  to { -webkit-transform: rotate(360deg); }
}
span.emoji {
  font-size: 30px;
  vertical-align: middle;
  line-height: 2;
  text-align: center
}

.callIcon {
  padding-left: 10px;
}

.print {
  display: flex;
  justify-content: flex-end;
}

.print-button {
  display: flex;
  align-content: center;
  justify-content: center;
  border-radius: 4px;
  font-size: 1rem;
}

.print-button:hover {
  cursor: pointer;
}

.print-icon {
  font-size: 1rem;
  margin: auto;
  padding-left: 0.2rem;
}



